<template>
  <div class="login-msg">
    <div class="login-kind">
      <p class="each-kind" :class="{'active':item.active}" v-for="(item,index) in kindArr" :key="index" @click="kindTab(index)">{{item.name}}</p>
    </div>
    <!-- 账号密码登录 -->
    <div v-show="kindArr[0].active && personalLoginType === 0">
      <div class="input-box">
        <p class="input-text-box">
          <input type="text" class="input-text" v-model="userName" @keyup.enter="userLogin" @blur="inputBlur('loginname')" placeholder="请输入用户名、手机号或邮箱">
          <span class="icon-img user"></span>
          <span class="error-tip" v-if="isUserTip">{{userTip}}</span>
        </p>
        <p class="input-text-box">
          <input :type="psdType?'password':'text'" class="input-text" v-model="loginPwd" @keyup.enter="userLogin" @blur="inputBlur('password')" autocomplete="off" placeholder="请输入密码">
          <span class="icon-img psd" :class="{'not-show':psdType}" @click="psdType = !psdType"></span>
          <span class="error-tip" v-if="isPsdTip">{{psdTip}}</span>
        </p>
        <div class="more-input">
          <input type="text" class="input-text" v-model="verifyCode" @keyup.enter="userLogin" @blur="inputBlur('verifyCode')" placeholder="请输入验证码">
          <a class="img-code" @click="reloadCode">
            <img :src="verificationUrl" alt="">
          </a>
          <span class="error-tip" v-if="isVerifyCode">{{verifyCodeTip}}</span>
        </div>
      </div>
      <div class="other-link">
        <a class="forget-psd" @click="forgetPsd">忘记密码</a>
        <a class="forget-psd" @click="togglePersonal(1)">验证码登录</a>
        <a class="register" @click="registerNow">立即注册</a>
      </div>
      <a class="login-btn" @click="userLogin">登  录</a>
      <div class="read-file">
        <p class="file-tip">登录表示您已阅读并同意</p>
        <router-link to="/user_agreement" class="file-title" target="_blank">《用户协议》</router-link>
      </div>
    </div>
    <!-- 验证码登录 -->
    <div v-show="kindArr[0].active && personalLoginType === 1">
      <div class="input-box">
        <p class="input-text-box">
          <input type="text" class="input-text" v-model="codeName" maxlength="11" @keyup.enter="codeLogin" @blur="inputBlur('codeName')" placeholder="请输入手机号">
          <span class="icon-img user"></span>
          <span class="error-tip" v-if="isUserTip">{{userTip}}</span>
        </p>
        <div class="more-input">
          <input type="text" class="input-text" v-model="identifyCode" @keyup.enter="codeLogin" @blur="inputBlur('identifyCode')" placeholder="请输入验证码">
          <a class="identify-code" @click="getIdentifyCode('code')">{{codeText}}</a>
          <span class="error-tip" v-if="isCodeTip">{{codeTip}}</span>
        </div>
      </div>
      <div class="other-link">
        <a class="forget-psd" @click="togglePersonal(0)">密码登录</a>
        <a class="register" @click="registerNow">立即注册</a>
      </div>
      <a class="login-btn" @click="codeLogin">登  录</a>
      <div class="read-file">
        <p class="file-tip">登录表示您已阅读并同意</p>
        <router-link to="/user_agreement" class="file-title" target="_blank">《用户协议》</router-link>
      </div>
    </div>
    <!-- 企业登录 -->
    <div v-show="kindArr[1].active">
      <div class="input-box">
        <p class="input-text-box">
          <input type="text" class="input-text" v-model="companyName" @keyup.enter="companyLogin" @blur="inputBlur('companyName')" placeholder="请输入账号">
          <span class="icon-img user"></span>
          <span class="error-tip" v-if="isUserTip">{{userTip}}</span>
        </p>
        <p class="input-text-box">
          <input :type="psdType?'password':'text'" class="input-text" v-model="companyPwd" @keyup.enter="companyLogin" @blur="inputBlur('companyPwd')" autocomplete="off" placeholder="请输入密码">
          <span class="icon-img psd" :class="{'not-show':psdType}" @click="psdType = !psdType"></span>
          <span class="error-tip" v-if="isPsdTip">{{psdTip}}</span>
        </p>
        <div class="more-input">
          <input type="text" class="input-text" v-model="verifyCode" @keyup.enter="companyLogin" @blur="inputBlur('verifyCode')" placeholder="请输入验证码">
          <a class="img-code" @click="reloadCode">
            <img :src="verificationUrl" alt="">
          </a>
          <span class="error-tip" v-if="isVerifyCode">{{verifyCodeTip}}</span>
        </div>
      </div>
      <div class="other-link">
        <!-- <a class="forget-psd" @click="forgetPsd">忘记密码</a>
        <a class="register" @click="registerNow">立即注册</a> -->
      </div>
      <a class="login-btn" @click="companyLogin">登  录</a>
      <div class="read-file">
        <p class="file-tip">登录表示您已阅读并同意</p>
        <router-link to="/user_agreement" class="file-title" target="_blank">《用户协议》</router-link>
      </div>
    </div>
    <!-- <div class="codeNews" v-if="isShowCodeNews">
       {{codeNewsText}}
    </div> -->
    <loginTip :tipMsg="tipMsg"/>
  </div>
</template>
<script>
import "@/assets/css/login.less";
import { setToken } from '@/utils/auth'
import { getUserLogin,getPhoneCheck,
// getToCUserInfo,
businessLogin,getBusinessInfo,getVerificationCode } from "@/api/login"
import { Encrypt } from '@/utils/cryptoJS'
import interfaceBack from '@/mixins/interfaceBack.js'
import blur from '../mixins/blur.js'
import loginTip from "@/components/loginTip"
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  components: {loginTip},
  mixins: [interfaceBack,blur,BuryingPont],
  data () {
    return {
      kindArr: [{name:'个人登录',active: true},{name:'企业登录',active: false}],
      userName: '',
      loginPwd: '',
      psdType: true,
      codeName: '',
      identifyCode: '',
      codeNum: 60,
      timeFlag: false,
      companyName: '',
      companyPwd: '',
      codeText: '获取验证码',
      channelNo: 'XFAP',
      isUserTip: false,
      userTip: '',
      isPsdTip: false,
      psdTip: '',
      isCodeTip: false,
      codeTip: '',
      verifyCode: '',
      verificationKey: 'user_login',
      verificationUrl: '',
      verifyCodeTip: '',
      isVerifyCode: false,

      // isShowCodeNews: false,
      tipMsg: {
        code: '',
        text: ''
      },
      loginFlag: false,
      personalLoginType: 0 // 0：密码登录、1：验证码登录
    }
  },
  created () {
    this.getVerificationCode();
  },
  methods: {
    // 获取图形验证码
    getVerificationCode(){

      getVerificationCode({verificationKey:this.verificationKey}).then(res=>{
        if(this.$store.state.baseUrl.indexOf("http")>=0){
          this.verificationUrl = this.$store.state.baseUrl+res.data.url;
        }else{
          this.verificationUrl = res.data.url;
        }
      }).catch(()=>{})
    },
    // 立即注册
    registerNow(){
      this.$emit("goRegister",true);
    },
    // 忘记密码
    forgetPsd(){
      this.$emit("forgetPsd",true);
    },
    // 登录类型切换
    kindTab(index){
      if(index == 0){
        this.verificationKey = 'user_login';
      }else if(index == 2){
        this.verificationKey = 'business_login';
      }
      // if(index !== 1) this.isShowCodeNews = false
      if(!this.kindArr[index].active && this.personalLoginType === 1){
        this.getVerificationCode();
      }
      for(var i=0;i<this.kindArr.length;i++){
        if(i==index){
          this.kindArr[i].active = true;
        }else{
          this.kindArr[i].active = false;
        }
      }
      this.isUserTip = false;
      this.userTip = '';
      this.isPsdTip = false;
      this.psdTip = '';
      this.isCodeTip = false;
      this.codeTip = '';
      this.psdType = true;
      this.verifyCode = '';
      this.verifyCodeTip = '';
      this.isVerifyCode = false;

      this.userName = "";
      this.loginPwd = "";
      this.verifyCode = "";
      this.codeName = "";
      this.identifyCode = "";
      this.companyName = "";
      this.companyPwd = "";
    },
    // 切换个人登录方式
    togglePersonal(type) {
      this.personalLoginType = type
      this.kindTab(0)
    },
    // 用户密码登录
    userLogin(){
      // 点击登录埋点
      this.sensors.track( 'LY_click_login_button' ,{
        AppName: this.$store.state.AppName, 
        accout_type: '手机号/邮箱',
        login_mode: '密码登录',
        is_login: false, 
        platform: this.$store.state.platform
      })
      // this.isUserTip = false;
      // this.isPsdTip = false;
      // this.isVerifyCode = false;
      var loginData = {
        userName: this.userName,
        accPwd: Encrypt(this.loginPwd,this.$store.state.businessPKI),
        loginType: '1',
        // pwdType: 2, // 密码是否明文（1为明文，2为密文）
        // channelNo: this.channelNo,
        verificationKey: this.verificationKey,
        verificationCode: this.verifyCode,
        type: "PC"
      }
      // if(this.userName.trim() == ''){
      //   this.isUserTip = true;
      //   this.userTip = '请输入手机号';
      // }else if(!this.checkPhone(this.userName)){
      //   this.isUserTip = true;
      //   this.userTip = '请输入正确的手机号';
      // }else if(this.loginPwd.trim() == ''){
      //   this.isPsdTip = true;
      //   this.psdTip = '请输入密码';
      // }else if(this.verifyCode.trim() == ''){
      //   this.isVerifyCode = true;
      //   this.verifyCodeTip = '请输入图形验证码';
      // }else
      this.inputBlur('loginname');
      this.inputBlur('password');
      this.inputBlur('verifyCode');
      if(!this.isUserTip && !this.isPsdTip && !this.isVerifyCode){
        if(!this.loginFlag){
          this.loginFlag = true;
          getUserLogin(loginData).then(res=>{
            if(res.code == 306){
              this.isVerifyCode = true;
              this.verifyCodeTip = '验证码错误';
              this.getVerificationCode();
              this.loginFlag = false;
            }else{
              if(res.code == 200){
                this.isUserTip = false;
                this.isPsdTip = false;
                setToken(res.data.accessToken)
                // this.$cookies.set("token",res.data.accessToken, null, null, null, true);
                this.$store.commit('SET_TOKEN',res.data.accessToken);
                this.$store.dispatch('getUserInfo', this).then(() => {
                  this.$router.push('/homepage')
                  // this.$router.back();
                })
                // 密码登录成功埋点
                this.sensors.track( 'LY_click_login_button' ,{
                  AppName: this.$store.state.AppName, 
                  accout_type: '手机号/邮箱',
                  login_mode: '密码登录',
                  is_login: true, 
                  platform: this.$store.state.platform
                })
              }else if(res.code == 2006){
                this.getVerificationCode();
                this.userTip = '账号未注册';
                this.isUserTip = true;
              }else if(res.code == 2016){
                this.getVerificationCode();
                this.userTip = '请输入正确的账号和密码';
                this.isUserTip = true;
                this.psdTip = '请输入正确的账号和密码';
                this.isPsdTip = true;
              }else{
                this.getVerificationCode();
                this.tipMsg = {code:res.code,text:res.msg}
              }
              this.loginFlag = false;
            }
          }).catch(()=>{
            this.loginFlag = false;
          });
        }
      }
    },
    // 手机号校验
    checkPhone(num) {
      return /^(13|14|15|16|17|18|19)\d{9}$/.test(num);
    },
    // 邮箱校验
    checkEmail(num) {
      return  /^\w+@[a-z0-9]+(\.[a-z]+){1,3}$/.test(num);
    },
    // 短信登录
    codeLogin(){
      // 点击登录埋点
      this.sensors.track( 'LY_click_login_button' ,{
        AppName: this.$store.state.AppName, 
        accout_type: '手机号',
        login_mode: '短信登录',
        is_login: false, 
        platform: this.$store.state.platform
      })
      // this.isUserTip = false;
      // this.isCodeTip = false;
      // if(this.codeName.trim() == ''){
      //   this.isUserTip = true;
      //   this.userTip = '请输入手机号';
      // }else if(!this.checkPhone(this.codeName)){
      //   this.isUserTip = true;
      //   this.userTip = '请输入正确的手机号';
      // }else if(this.identifyCode.trim() == ''){
      //   this.isCodeTip = true;
      //   this.codeTip = '请输入验证码';
      // }else
      this.inputBlur('codeName');
      this.inputBlur('identifyCode');
      if(!this.isUserTip && !this.isCodeTip){
        if(!this.loginFlag){
          this.loginFlag = true;
          var codeData = {
            userName: this.codeName,
            msgCode: this.identifyCode,
            loginType: '2',
            // channelNo: this.channelNo,
            type: "PC"
          }
          getUserLogin(codeData).then(res=>{
            if(res.code == 200){
              this.isUserTip = false;
              this.isCodeTip = false;
              setToken(res.data.accessToken)
              // this.$cookies.set("token",res.data.accessToken, null, null, null, true);
              this.$store.commit('SET_TOKEN',res.data.accessToken);
              // 获取客户服务器的个人信息
              // getUserInfo({userName:this.userName,channelNo: this.channelNo}).then(req=>{
              //   if(req.data.Header.RetCode == '0000'){
              //     var userInfoMsg = req.data.Body;
              //     this.$store.commit('changeUserInfo',userInfoMsg);
              //   }
              // }).catch(()=>{})
              // 获取我们服务器的个人信息
              // getToCUserInfo().then(req=>{
              //   if(req.code == 0){
              //     this.$store.commit('changeLocalInfo',req.data);
              //   }
              // })
              this.$store.dispatch('getUserInfo',this).then(() => {
                this.$router.push('/homepage')
                // this.$router.back();
              })
              // 短信登录成功埋点
              this.sensors.track( 'LY_click_login_button' ,{
                AppName: this.$store.state.AppName, 
                accout_type: '手机号',
                login_mode: '短信登录',
                is_login: true, 
                platform: this.$store.state.platform
              })
            }else if(res.code == 2022){
              this.isCodeTip = true;
              this.codeTip = '请输入正确的验证码';
            }else if(res.code == 2023){
              this.isCodeTip = true;
              this.codeTip = '验证码已失效，请重新获取';
            }else{
              this.tipMsg = {code:res.code,text:res.msg}
              // this.isCodeTip = true;
              // this.codeTip = res.data.Header.RetMessage;
              // this.$message({tipTxt:'服务器开小差了，请稍后再试',isLink:false});
              // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
            }
            this.loginFlag = false;
          }).catch(()=>{
            console.log("err")
            this.loginFlag = false;
          }).finally(()=>{
            console.log("123456")
          })
        }
      }
    },
    // 获取验证码
    getIdentifyCode(data){
      var getCode;
      // var Time
      var identifyData = {
        userPhone: this.codeName,
        // channelNo: this.channelNo,
      };
      this.isUserTip = false;
      this.isCodeTip = false;
      if(this.codeName.trim() == ''){
        this.isUserTip = true;
        this.userTip = '请输入手机号';
      }else if(!this.checkPhone(this.codeName)){
        this.isUserTip = true;
        this.userTip = '请输入正确的手机号';
      }else{
        if(!this.timeFlag){
          this.timeFlag = true;
          getPhoneCheck(identifyData).then((res)=>{
            if(res.code == -1){
              this.timeFlag = false;
              this.tipMsg = {code:'',text:res.msg}
            }else if(res.code == 200){
              if(data === 'code') {
                // this.isShowCodeNews = true
                this.tipMsg = {code:'',text:'验证码发送成功'}
                // Time = setTimeout(() => {
                //   clearTimeout(Time)
                //   this.isShowCodeNews = false
                // }, 3000)
              }
              this.codeText = this.codeNum+'s';
              getCode = setInterval(() => {
                this.codeNum--;
                if(this.codeNum<=0){
                  clearInterval(getCode);
                  this.codeText = "重新获取";
                  this.codeNum = 60;
                  this.timeFlag = false;
                }else{
                  this.codeText = this.codeNum+'s';
                }
              }, 1000);
            }
            // else if(res.code == '9999'){
            //   this.tipMsg = {code:'',text:'验证码发送失败'}
            //   this.timeFlag = false;
            // }
            else{
              this.timeFlag = false;
              this.tipMsg = {code:res.code,text:res.msg}
              // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
            }
          }).catch(()=>{
            this.timeFlag = false;
          })
        }
      }
    },
    // 企业登录
    companyLogin(){
      // 点击登录埋点
      this.sensors.track( 'LY_click_login_button' ,{
        AppName: this.$store.state.AppName, 
        accout_type: '账号',
        login_mode: '企业账号密码登录',
        is_login: false, 
        platform: this.$store.state.platform
      })
      // this.isUserTip = false;
      // this.isPsdTip = false;
      // this.isVerifyCode = false;
      // if(this.companyName.trim() == ''){
      //   this.isUserTip = true;
      //   this.userTip = '请输入账号';
      // }else if(this.companyPwd.trim() == ''){
      //   this.isPsdTip = true;
      //   this.psdTip = '请输入密码';
      // }else if(this.verifyCode.trim() == ''){
      //   this.isVerifyCode = true;
      //   this.verifyCodeTip = '请输入图形验证码';
      // }else
      this.inputBlur('companyName');
      this.inputBlur('companyPwd');
      this.inputBlur('verifyCode');
      if(!this.isUserTip && !this.isPsdTip && !this.isVerifyCode){
        if(!this.loginFlag){
          this.loginFlag = true;
          var businessData = {
            username: this.companyName,
            password: Encrypt(this.companyPwd,this.$store.state.businessPKI),
            verificationKey: this.verificationKey,
            verificationCode: this.verifyCode,
            type: '1' // 1为PC端登录,2为移动端登录
          }
          businessLogin(businessData).then(res=>{
            if(res.code == 306){
              this.isVerifyCode = true;
              this.verifyCodeTip = '请输入正确的验证码';
              this.getVerificationCode();
            }else if(res.code == -1){
              this.isUserTip = true;
              this.userTip = '请输入正确的账号和密码';
              this.isPsdTip = true;
              this.psdTip = '请输入正确的账号和密码';
              this.getVerificationCode();
            }else if(res.code == 0){
              setToken(res.msg)
              // this.$cookies.set("token",res.msg, null, null, null, true);
              this.$store.commit('SET_TOKEN',res.msg);
              getBusinessInfo().then(req=>{
                if(req.code == 0){
                  this.$store.commit('SET_USERINFO',req.data)
                  this.$router.push('/homepage')
                  // this.$router.back();
                }
              }).catch(()=>{})
              // 企业登录成功埋点
              this.sensors.track( 'LY_login_success' ,{
                AppName: this.$store.state.AppName, 
                accout_type: '账号',
                login_mode: '企业账号密码登录',
                is_login: true, 
                platform: this.$store.state.platform
              })
              
            }else{
              this.tipMsg = {code:res.code,text:res.msg}
              // this.interfaceBack(res.code,res.msg);
              this.getVerificationCode()
            }
            this.loginFlag = false;
          }).catch(()=>{
            this.loginFlag = false;
          })
        }
      }
    },
    // 重新加载验证码
    reloadCode(){
      this.getVerificationCode();
    }
  }
}
</script>
<style lang="less" scoped>
.login-msg{
  position: relative;
}
// .codeNews{
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   width: 180px;
//   height: 84px;
//   text-align: center;
//   line-height:83px;
//   background: #fff;
//   box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05), 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);
//   border-radius: 4px;
//   font-size: 14px;
//   color: rgba(0, 0, 0, 0.65);
//   margin-left: -90px;
//   margin-top: -42px;
// }
</style>
