<template>
  <div class="login-msg">
    <div class="login-kind">
      <p class="each-kind active">注册</p>
    </div>
    <div class="input-box">
      <p class="input-text-box">
        <input type="text" class="input-text" v-model="userName" @keyup.enter="userRegister" @blur="inputBlur('username')"  maxlength="11" placeholder="请输入手机号">
        <span class="icon-img phone"></span>
        <span class="error-tip" v-if="isUserTip">{{userTip}}</span>
      </p>
      <p class="input-text-box">
        <input :type="psdType?'password':'text'" class="input-text" v-model="newPsd" @keyup.enter="userRegister" @blur="inputBlur('newPsd')" maxlength="16" autocomplete="off" placeholder="请输入密码">
        <span class="icon-img psd" :class="{'not-show':psdType}" @click="psdType = !psdType"></span>
          <span class="error-tip" v-if="isPsdTip">{{psdTip}}</span>
      </p>
      <p class="input-text-box">
        <input :type="psdTypeCon?'password':'text'" class="input-text" v-model="confirmPsd" @keyup.enter="userRegister" @blur="inputBlur('confirmPsd')" maxlength="16" autocomplete="off" placeholder="确认密码">
        <span class="icon-img psd" :class="{'not-show':psdTypeCon}" @click="psdTypeCon = !psdTypeCon"></span>
          <span class="error-tip" v-if="isConfPsdTip">{{confPsdTip}}</span>
      </p>
      <div class="more-input">
        <input type="text" class="input-text" v-model="identifyCode" @keyup.enter="userRegister" @blur="inputBlur('identifyCode')" placeholder="请输入验证码">
        <a class="identify-code" @click="getIdentifyCode">{{codeText}}</a>
        <span class="error-tip" v-if="isCodeTip">{{codeTip}}</span>
      </div>
    </div>
    <div class="other-link">
      <a class="register" @click="loginNow">已有账号登录</a>
    </div>
    <a class="login-btn" @click="userRegister">注 册</a>
    <div class="read-file">
      <p class="file-tip change-read" @click="readFile"><span class="check-file" :class="{'is-checked':isReadFile}"></span>已阅读并同意</p>
        <router-link to="/user_agreement" class="file-title" target="_blank">《用户协议》</router-link>
        <span class="read-file-tip" v-if="notRead">请先阅读用户协议</span>
    </div>
     <!-- <div class="codeNews" v-if="isShowCodeNews">
       {{codeNewsText}}
    </div> -->
    <loginTip :tipMsg="tipMsg"/>
    <!-- <Tips v-if="loginErr" :abnormalTxt="errTxt" @closeModel="closeModel"/> -->
    <!-- <div class="model question_model" v-if="registerDone">
      <div class="feedback_success">
        <div class="feedback_title">提示</div>
        <p class="abnormal-tips">注册成功，请
          <span class="login-link" @click="regSuccess">登录</span>
        </p>
        <span class="feedback_success_button" @click="regSuccess">去登陆</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import "@/assets/css/login.less";
import { getUserRegister,regPhoneCheck,getUserRegisterCheck } from '@/api/login';
import { Encrypt } from '@/utils/cryptoJS'
// import Tips from '@/components/Tips'
import interfaceBack from '@/mixins/interfaceBack.js'
import blur from '../mixins/blur.js'
import loginTip from "@/components/loginTip"
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  mixins: [interfaceBack,blur,BuryingPont],
  components: {loginTip},
  data () {
    return {
      userName: '',
      identifyCode: '',
      codeName: '',
      codeNum: 60,
      psdType: true,
      psdTypeCon: true,
      timeFlag: false,
      codeText: '获取验证码',
      channelNo: 'XFAP',
      newPsd: '',
      confirmPsd: '',
      isReadFile: false,
      isUserTip: false,
      userTip: '',
      isPsdTip: false,
      psdTip: '',
      isConfPsdTip: false,
      confPsdTip: '',
      isCodeTip: false,
      codeTip: '',
      notRead: false,
      // registerDone: false,

      // isShowCodeNews: false,
      tipMsg: {
        code: '',
        text: ''
      },
      registerFlag: false
      // loginErr: false,
      // errTxt: ''
    }
  },
  created () {},
  methods: {
    // 已有账号去登录
    loginNow(){
      this.$emit("goLogin",true);
    },
    // 手机号校验
    checkPhone(num) {
      return /^(13|14|15|16|17|18|19)\d{9}$/.test(num);
    },
    // 获取验证码
    getIdentifyCode(){
      var getCode;
      // var Time;
      var identifyData = {
        userPhone: this.userName,
        // channelNo: this.channelNo,
      };
      // this.isUserTip = false;
      // this.isCodeTip = false;
      // this.notRead = false;
      if(this.userName == ''){
        this.isUserTip = true;
        this.userTip = '请输入手机号';
      }else if(!this.checkPhone(this.userName)){
        this.isUserTip = true;
        this.userTip = '请输入正确的手机号';
      }else{
        
        if(!this.timeFlag){
          this.timeFlag = true;
          getUserRegisterCheck({ 
            userPhone: this.userName,
            // channelNo: this.channelNo 
          }).then((req)=>{
            this.isCodeTip = false;
            this.codeTip = '';
            if(req.code == 2007){
              this.isUserTip = true;
              this.userTip = '该手机号已注册';
              this.timeFlag = false;
            } else if(req.code == 2008){
              regPhoneCheck(identifyData).then((res)=>{
                if(res.code == -1){
                  this.timeFlag = false
                  // this.tipMsg = {code:'',text:res.msg}
                  this.isCodeTip = true;
                  this.codeTip = res.msg;
                }else if(res.code == 200){
                  // this.isShowCodeNews = true
                  this.tipMsg = {code:'',text:'验证码发送成功'}
                  // Time = setTimeout(() => {
                  //   clearTimeout(Time)
                  //   this.isShowCodeNews = false
                  // }, 3000)

                  this.codeText = this.codeNum+'s';
                  getCode = setInterval(() => {
                    this.codeNum--;
                    if(this.codeNum<=0){
                      clearInterval(getCode);
                      this.codeText = "重新获取";
                      this.codeNum = 60;
                      this.timeFlag = false;
                    }else{
                      this.codeText = this.codeNum+'s';
                    }
                  }, 1000);
                }else{
                  this.timeFlag = false
                  this.tipMsg = {code:res.code,text:res.msg}
                  // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
                }
              }).catch(()=>{
                this.timeFlag = false;
              })
            }else{
              this.timeFlag = false
              this.tipMsg = {code:req.code,text:req.msg}
            }
          }).catch(()=>{})
        }
      }
    },
    userRegister(){
      // this.isUserTip = false;
      // this.isPsdTip = false;
      // this.isCodeTip = false;
      this.notRead = false;
      this.getUserRegister();
      // 点击注册埋点
      this.sensors.track( 'LY_click_register_button' ,{
        AppName: this.$store.state.AppName, 
        PlatformType: '手机号',
        is_login: false, 
        platform: this.$store.state.platform
      })
    },
    // 验证码校验
    // checkIdentifyCode(){
    //   if(!this.registerFlag){
    //     this.registerFlag = true;
    //     var registerData={
    //       userName: this.userName,
    //       checkCode: this.identifyCode,
    //       channelNo: this.channelNo,
    //     }
    //     loginByPhone(registerData).then(res=>{
    //       if(res.data.Header.RetCode == '0000'){
    //         this.isUserTip = false;
    //         this.isCodeTip = false;
    //         this.notRead = false;
    //         this.checkRegister();
    //       }else if(res.data.Header.RetCode == '0048'){
    //         this.isCodeTip = true;
    //         this.codeTip = '请输入正确的验证码';
    //         this.registerFlag = false;
    //       }else if(res.data.Header.RetCode == '0049'){
    //         this.isCodeTip = true;
    //         this.codeTip = '验证码已失效，请重新获取';
    //         this.registerFlag = false;
    //       }else{
    //         // this.isCodeTip = true;
    //         // this.codeTip = res.data.Header.RetMessage;
    //         this.tipMsg = {code:res.data.Header.RetCode,text:res.data.Header.RetMessage}
    //         // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
    //         this.registerFlag = false;
    //       }
    //     }).catch(()=>{
    //       this.registerFlag = false;
    //     })
    //   }
    // },
    // 注册校验
    checkRegister(){
      var registerPsd = {
        // perName: this.userName,
        userPhone: this.userName,
        accPwd: Encrypt(this.newPsd,this.$store.state.businessPKI),
        msgCode: this.identifyCode,
        // channelNo: this.channelNo,
      }
      getUserRegister(registerPsd).then(res=>{
        if(res.code == 200){
          var that = this;
          this.isUserTip = false;
          this.isPsdTip = false;
          this.isCodeTip = false;
          this.notRead = false;
          // this.registerDone = true;
          that.tipMsg = {code:'',text:"注册成功，请前往登录"}
          setTimeout(function () {
            that.$emit("goLogin",true);
          }, 3000);
          // 注册成功埋点
          this.sensors.track( 'LY_register_success' ,{
            AppName: this.$store.state.AppName, 
            is_login: false, 
            platform: this.$store.state.platform
          })
        }else{
          this.tipMsg = {code:res.code,text:res.msg}
          // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
        }
        this.registerFlag = false;
      }).catch(()=>{
        this.registerFlag = false;
      })
    },
    // 用户注册去登录
    getUserRegister(){
      // var newCount = 0,confrimCount = 0;
      // for(var i in this.$store.state.psdRegexp){
      //   if(this.$store.state.psdRegexp[i].test(this.newPsd)){
      //     newCount++;
      //   }
      //   if(this.$store.state.psdRegexp[i].test(this.confirmPsd)){
      //     confrimCount++;
      //   }
      // }
      // if(this.userName.trim() == ''){
      //   this.isUserTip = true;
      //   this.userTip = '请输入手机号';
      // }else if(!this.checkPhone(this.userName)){
      //   this.isUserTip = true;
      //   this.userTip = '请输入正确的手机号';
      // }else if(this.newPsd.trim() == ''){
      //   this.isPsdTip = true;
      //   this.psdTip = '请输入密码';
      // }else if(this.newPsd != this.confirmPsd){
      //   this.isPsdTip = true;
      //   this.psdTip = '两次输入的密码不一致';
      // }else if(this.$store.state.noPsdRegexp.test(this.newPsd) || this.$store.state.noPsdRegexp.test(this.confirmPsd) || this.newPsd.length<8 || newCount<2 || this.confirmPsd.length<8 || confrimCount<2){
      //   this.isPsdTip = true;
      //   this.psdTip = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)';
      // }else if(this.identifyCode.trim() == ''){
      //   this.isUserTip = false;
      //   this.isCodeTip = true;
      //   this.codeTip = '请输入验证码';
      //}else 
      this.inputBlur('username');
      this.inputBlur('newPsd');
      this.inputBlur('confirmPsd');
      this.inputBlur('identifyCode');
      if(!this.isReadFile){
        this.notRead = true;
      }else if(!this.isUserTip && !this.isCodeTip && !this.isPsdTip && !this.isConfPsdTip){
        // this.checkIdentifyCode()
        this.checkRegister();
      }
    },
    // 注册成功，去登陆
    // regSuccess(){
    //   this.$emit("goLogin",true);
    //   this.registerDone = false;
    // },
    // 阅读协议
    readFile(){
      this.isReadFile = !this.isReadFile;
    },
    // 关闭报错弹窗
    // closeModel(data){
    //   this.loginErr = data.tipFlag;
    //   this.errTxt = data.tipText;
    // }
  }
}
</script>
<style lang="less" scoped>
.login-msg{
  position: relative;
}
// .codeNews{
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   width: 180px;
//   height: 84px;
//   text-align: center;
//   line-height:83px;
//   background: #fff;
//   box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05), 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);
//   border-radius: 4px;
//   font-size: 14px;
//   color: rgba(0, 0, 0, 0.65);
//   margin-left: -90px;
//   margin-top: -42px;
// }
.feedback_success {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 26px 0 0 0;
    transform: translate(-50%, -50%);
    width: 332px;
    height: 224px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    .feedback_title {
      line-height: 24px;
      font-size: 24px;
      color: #141825;
    }
    .abnormal-tips {
      margin: 50px 0 59px 0;
      font-size: 14px;
      color: #141825;
      .login-link{
        color: #005BAC;
        cursor: pointer;
      }
    }
    // .feedback_success_icon {
    //   width: 80px;
    //   height: 80px;
    //   background: url("../img/side/success.png") no-repeat center;
    //   margin: 48px auto;
    // }
    .feedback_success_button {
      display: inline-block;
      width: 120px;
      height: 40px;
      background: #e5e5e5;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      background: #005BAC;
      line-height: 40px;
      cursor: pointer;
    }
  }
</style>