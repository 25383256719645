<template>
  <div class="login-msg">
    <div class="login-kind">
      <p class="each-kind active">找回密码</p>
    </div>
    <div class="input-box">
      <p class="input-text-box">
        <input type="text" class="input-text" v-model="userName" @keyup.enter="userRegister" @blur="inputBlur('username')" maxlength="11" placeholder="请输入手机号">
        <span class="icon-img phone"></span>
        <span class="error-tip" v-if="isUserTip">{{userTip}}</span>
      </p>
      <p class="input-text-box">
        <input :type="psdType?'password':'text'" class="input-text" v-model="newPsd" @keyup.enter="userRegister" @blur="inputBlur('newPsd')" maxlength="16" autocomplete="off" placeholder="请输入新密码">
        <span class="icon-img psd" :class="{'not-show':psdType}" @click="psdType = !psdType"></span>
        <span class="error-tip" v-if="isPsdTip">{{psdTip}}</span>
      </p>
      <p class="input-text-box">
        <input  :type="psdTypeCon?'password':'text'" class="input-text" v-model="confirmPsd" @keyup.enter="userRegister" @blur="inputBlur('confirmPsd')" maxlength="16" autocomplete="off" placeholder="确认新密码">
        <span class="icon-img psd" :class="{'not-show':psdTypeCon}" @click="psdTypeCon = !psdTypeCon"></span>
        <span class="error-tip" v-if="isConfPsdTip">{{confPsdTip}}</span>
      </p>
      <div class="more-input">
        <input type="text" class="input-text" v-model="identifyCode" @keyup.enter="userRegister" @blur="inputBlur('identifyCode')" placeholder="请输入验证码">
        <a class="identify-code" @click="getIdentifyCode">{{codeText}}</a>
        <span class="error-tip" v-if="isCodeTip">{{codeTip}}</span>
      </div>
    </div>
    <div class="other-link">
      <p class="other-tip new_img">
        <span>暂不支持邮箱账号重置密码</span>
        <img src="~@/assets/img/login/prompt_help.svg" alt="" @mouseover="helpOver" @mouseout="helpOut">
      </p>
      <div class="popper">暂不支持邮箱账号重置密码，请访问新华财经专业终端进行操作</div>
      <a class="register" @click="registerNow">立即注册</a>
    </div>
    <a class="login-btn" @click="userRegister">设置密码</a>
    <div class="read-file">
      <p class="file-tip">点击按钮表示您已阅读并同意</p>
        <router-link to="/user_agreement" class="file-title" target="_blank">《用户协议》</router-link>
    </div>
    <!-- <div class="codeNews" v-if="isShowCodeNews">
       {{codeNewsText}}
    </div> -->
    <loginTip :tipMsg="tipMsg"/>
  </div>
</template>
<script>
import "@/assets/css/login.less";
import { getPhoneCheck,restartPwd,getUserRegisterCheck } from '@/api/login';
import { Encrypt } from '@/utils/cryptoJS'
import interfaceBack from '@/mixins/interfaceBack.js'
import blur from '../mixins/blur.js'
import loginTip from "@/components/loginTip"
export default {
  components: {loginTip},
  mixins: [interfaceBack,blur],
  data () {
    return {
      userName: '',
      identifyCode: '',
      codeName: '',
      codeNum: 60,
      psdType: true,
      psdTypeCon: true,
      timeFlag: false,
      codeText: '获取验证码',
      channelNo: 'XFAP',
      newPsd: '',
      confirmPsd: '',
      isUserTip: false,
      userTip: '',
      isPsdTip: false,
      psdTip: '',
      isConfPsdTip: false,
      confPsdTip: '',
      isCodeTip: false,
      codeTip: '',
      newSetFlag: false,
      // isShowCodeNews: false,
      tipMsg: {
        code: '',
        text: ''
      },
    }
  },
  created () {},
  methods: {
    // 触摸帮助图标
    helpOver(){
      document.querySelector('.popper').style.display='block'
    },
    // 离开帮助图标
    helpOut(){
      document.querySelector('.popper').style.display='none'
    },
    // 立即注册
    registerNow(){
      this.$emit("goRegister",true);
    },
    // 手机号校验
    checkPhone(num) {
      return /^(13|14|15|16|17|18|19)\d{9}$/.test(num);
    },
    // 获取验证码
    getIdentifyCode(){
      var getCode;
      // var Time;
      var identifyData = {
        userPhone: this.userName,
        // channelNo: this.channelNo,
      };
      // this.isUserTip = false;
      // this.isCodeTip = false;
      if(this.userName == ''){
        this.isUserTip = true;
        this.userTip = '请输入手机号';
      }else if(!this.checkPhone(this.userName)){
        this.isUserTip = true;
        this.userTip = '请输入正确的手机号';
      }else{
        if(!this.timeFlag){
          this.timeFlag = true;
          getUserRegisterCheck({ userPhone: this.userName }).then((req)=>{
            console.log(req)
            this.isCodeTip = false;
            this.codeTip = '';
            if(req.code == 2007){
              getPhoneCheck(identifyData).then((res)=>{
                if(res.code == -1){
                  this.timeFlag = false;
                  // this.tipMsg = {code:'',text:res.msg}
                  this.isCodeTip = true;
                  this.codeTip = res.msg;
                }else if(res.code == 200){
                  // this.isShowCodeNews = true
                  this.tipMsg = {code:'',text:'验证码发送成功'}
                  // Time = setTimeout(() => {
                  //   clearTimeout(Time)
                  //   this.isShowCodeNews = false
                  // }, 3000)

                  this.codeText = this.codeNum+'s';
                  getCode = setInterval(() => {
                    this.codeNum--;
                    if(this.codeNum<=0){
                      clearInterval(getCode);
                      this.codeText = "重新获取";
                      this.codeNum = 60;
                      this.timeFlag = false;
                    }else{
                      this.codeText = this.codeNum+'s';
                    }
                  }, 1000);
                }else{
                  this.timeFlag = false
                  this.tipMsg = {code:res.code,text:res.msg}
                  // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
                }
              }).catch(()=>{
                this.timeFlag = false;
              })
            }else if(req.code == 2008){
              this.isUserTip = true;
              this.userTip = '您的账号尚未注册';
              this.timeFlag = false;
            }else{
              this.timeFlag = false;
              this.tipMsg = {code:req.data.Header.RetCode,text:req.data.Header.RetMessage}
              // this.interfaceBack(req.data.Header.RetCode,req.data.Header.RetMessage);
            }
          }).catch(()=>{
            this.timeFlag = false;
          })
        }
      }
    },
    userRegister(){
      // this.isUserTip = false;
      // this.isPsdTip = false;
      // this.isCodeTip = false;
      this.setNewPsd();
    },
    // 验证码校验
    // checkIdentifyCode(){
    //   if(!this.newSetFlag){
    //     this.newSetFlag = true;
    //     var registerData={
    //       userName: this.userName,
    //       checkCode: this.identifyCode,
    //       // channelNo: this.channelNo,
    //     }
    //     loginByPhone(registerData).then(res=>{
    //       if(res.data.Header.RetCode == '0000'){
    //         this.isUserTip = false;
    //         this.isCodeTip = false;
    //         this.checkNewPsd();
    //       }else if(res.data.Header.RetCode == '0048'){
    //         this.isCodeTip = true;
    //         this.codeTip = '请输入正确的验证码';
    //         this.newSetFlag = false;
    //       }else if(res.data.Header.RetCode == '0049'){
    //         this.isCodeTip = true;
    //         this.codeTip = '验证码已失效，请重新获取';
    //         this.newSetFlag = false;
    //       }else{
    //         // this.isCodeTip = true;
    //         // this.codeTip = res.data.Header.RetMessage;
    //         this.tipMsg = {code:res.data.Header.RetCode,text:res.data.Header.RetMessage}
    //         // this.interfaceBack(res.data.Header.RetCode,res.data.Header.RetMessage);
    //         this.newSetFlag = false;
    //       }
    //     }).catch(()=>{
    //       this.newSetFlag = false;
    //     })
    //   }
    // },
    // 设置新密码接口
    checkNewPsd(){
      var registerPsd = {
        userPhone: this.userName,
        accPwd: Encrypt(this.newPsd,this.$store.state.businessPKI),
        msgCode: this.identifyCode,
        // channelNo: this.channelNo,
      }
      restartPwd(registerPsd).then(res=>{
        if(res.code == 200){
          var that = this;
          this.isUserTip = false;
          this.isPsdTip = false;
          this.isCodeTip = false;
          this.tipMsg = {code:'',text:'密码找回成功'}
          setTimeout(function() {
            that.$emit("goLogin",true);
          }, 3000);
        }else{
          // this.isPsdTip = true;
          // this.psdTip = res.data.Header.RetMessage;
          this.tipMsg = {code:res.code,text:res.msg}
        }
        this.newSetFlag = false;
      }).catch(()=>{
        this.newSetFlag = false;
      })
    },
    // 设置新密码
    setNewPsd(){
      // var newCount = 0,confrimCount = 0;
      // for(var i in this.$store.state.psdRegexp){
      //   if(this.$store.state.psdRegexp[i].test(this.newPsd)){
      //     newCount++;
      //   }
      //   if(this.$store.state.psdRegexp[i].test(this.confirmPsd)){
      //     confrimCount++;
      //   }
      // }
      // if(this.userName.trim() == ''){
      //   this.isUserTip = true;
      //   this.userTip = '请输入手机号';
      // }else if(!this.checkPhone(this.userName)){
      //   this.isUserTip = true;
      //   this.userTip = '请输入正确的手机号';
      // }else if(this.newPsd.trim() == ''){
      //   this.isPsdTip = true;
      //   this.psdTip = '请输入密码';
      // }else if(this.newPsd != this.confirmPsd){
      //   this.isPsdTip = true;
      //   this.psdTip = '两次输入的密码不一致';
      // }else if(this.$store.state.noPsdRegexp.test(this.newPsd) || this.$store.state.noPsdRegexp.test(this.confirmPsd) || this.newPsd.length<8 || newCount<2 || this.confirmPsd.length<8 || confrimCount<2){
      //   this.isPsdTip = true;
      //   this.psdTip = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)'; 
      // }else if(this.identifyCode.trim() == ''){
      //   this.isUserTip = false;
      //   this.isCodeTip = true;
      //   this.codeTip = '请输入验证码';
      // }else
      this.inputBlur('username');
      this.inputBlur('newPsd');
      this.inputBlur('confirmPsd');
      this.inputBlur('identifyCode');
      if(!this.isUserTip && !this.isCodeTip && !this.isPsdTip && !this.isConfPsdTip){
        // this.checkIdentifyCode();
        this.checkNewPsd();
      }
    },
  }
}
</script>
<style lang="less" scoped>
// .codeNews{
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   width: 180px;
//   height: 84px;
//   text-align: center;
//   line-height:83px;
//   background: #fff;
//   box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05), 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);
//   border-radius: 4px;
//   font-size: 14px;
//   color: rgba(0, 0, 0, 0.65);
//   margin-left: -90px;
//   margin-top: -42px;
// }
.login-msg{
  .other-link{
    position: relative;
    .new_img{
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
      }
      img{
        margin-left: 4px;
        cursor: pointer;
      }
    }
    .popper{
      display: none;
      position: absolute;
      padding: 13px 10px 13px 17px;
      width: 145px;
      height: 66px;
      background: #fff;
      border-radius: 8px;
      bottom: 28px;
      left: 90px;
      color: rgba(0, 0, 0, 65%);
      font-size: 14px;
      line-height: 22px;
      box-shadow:  0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    }
    .popper::after{
      content: "";
      border-width: 6px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      bottom: -6px;
      left: 83px;
      border-bottom-width: 0;
      border-top-color: #fff;
    }
  }
}

</style>