<template>
  <div class="codeNews" v-show="isShow">
    {{ showText }}
  </div>
</template>
<script>
export default {
  props: {
    tipMsg: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showText: '',
      isShow: false,
    };
  },
  watch: {
    tipMsg(newVal) {
      if(newVal.text.indexOf('用户数据异常')>=0){
        this.showText = '服务器开小差了，请稍后再试';
      }else if (newVal.code) {
        var code = newVal.code.toString()
        switch (code) {
          case '0007':
            this.showText = '您的登录信息已过期，请重新登录';
            break;
          case '2020':
            this.showText = '您的账号已注册';
            break;
          case '0002':
            this.showText = '请输入账号/密码/验证码';
            break;
          case '0044':
            this.showText = '用户不存在';
            break;
          case '1006':
            this.showText = '短信服务余量不足';
            break;
          // case '0003'://下方
          // case '0049'://下方
          // case '0048'://下方
          // case '0009'://下方
          case '0001':
          case '0004':
          case 'd0006':
          case '0008':
          case '0010':
          case '0013':
          case '0014':
          case '0015':
          case '0016':
          case '0018':
          case '0019':
          case '0020':
          case '1000':
          case '1003':
          case '1004':
          case '1007':
          case '1008':
          case '2001':
          case '2002':
          case '2003':
          case '2004':
          case '2005':
          case '2006':
          case '2007':
          case '2008':
          case '2009':
          case '2010':
          case '2011':
          case '2012':
          case '2013':
          case '2014':
          case '8999':
          case '9996':
          case '9997':
          case '9998':
          case '2022':
          case '-1':
          case '999':
            this.showText = newVal.text;
            break;
          default: {
            this.showText = '服务器开小差了，请稍后再试';
            break;
          }
        }
      }else{
        this.showText = newVal.text;
      }
      var Time;
      var that = this;
      this.isShow = true;
      Time = setTimeout(function() {
        clearTimeout(Time);
        that.isShow = false;
      }, 3000);
    },
  },
};
</script>
<style lang="less" scoped>
.codeNews {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 31px 39px;
  // width: 102px;
  // height: 84px;
  text-align: center;
  line-height: 22px;
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  transform: translate(-50%,-50%);
}
</style>