<template>
  <div class="login-box">
    <!-- <div class="all-box"> -->
      <router-link to="/" class="logo-icon">
        <img src="@/assets/img/login/logo.svg" alt="logo">
      </router-link>
      <router-link to="/" class="back-home">返回首页</router-link>
      <div class="login-msg-box">
        <div class="contract-us">
          <!-- <h3 class="title">Contact Us</h3>
          <p class="contract-tip">If you have any questions, you can give us feedback anytime and anywhere, and we will reply to you as soon as possible after receiving the feedback.</p>
          <a class="arrow-right"></a> -->
        </div>
        <!-- 登录 -->
        <Login v-if="isLogin"  @goRegister="goRegister" @forgetPsd="forgetPsd"/>
        <!-- 注册 -->
        <Register v-if="isRegister" @goLogin="goLogin"/>
        <!-- 找回密码 -->
        <NewPsd v-if="isGetPsd" @goRegister="goRegister" @goLogin="goLogin"/>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
// import { setCatalogTree } from '@/utils/store-data'
import Login from './components/login'
import Register from './components/register'
import NewPsd from './components/newPsd'
export default {
  components: {
    Login,
    Register,
    NewPsd
  },
  data(){
    return{
      isLogin: true,
      isRegister: false,
      isGetPsd: false,
    }
  },
  created () {
    // setCatalogTree(this.$store);
  },
  methods: {
    goRegister(data){
      this.isLogin = !data;
      this.isRegister = data;
      this.isGetPsd = !data;
    },
    forgetPsd(data){
      this.isLogin = !data;
      this.isRegister = !data;
      this.isGetPsd = data;
    },
    goLogin(data){
      this.isLogin = data;
      this.isRegister = !data;
      this.isGetPsd = !data;
    },
    // getPsd(data){
    //   this.isLogin = !data;
    //   this.isRegister = data;
    //   this.isGetPsd = !data;
    // }
  }
}
</script>
<style lang="less" scoped>
.login-box{
  position: relative;
  width: 100%;
  // height: 100%;

  min-width: 1200px;
  min-height: 100%;
  // height: inherit;
  // height: 100%;
  // min-height: 803px;
  background: url("./../../assets/img/login/login_bg.png") top center no-repeat;
  // background-size: 100% 100%;
  // overflow: hidden;
  // background-size: 100% 100%;
  // .all-box{
  //   // position: relative;
  //   margin: 0 auto;
  //   max-width: 1672px;
  //   min-width: 1200px;
  //   // height: inherit;
  //   height: 100%;
    .logo-icon{
      // position: absolute;
      // top: 64px;
      // left: 113px;
      margin: 64px 0 0 113px;
      width: 314px;
      height: 54px;
      display: inline-block;
    }
    .back-home{
      // position: absolute;
      // right: 113px;
      // top: 71px;
      float: right;
      margin: 71px 113px 0 0; 
      width: 118px;
      height: 38px;
      // display: inline-block;
      border: 1px solid rgba(253, 254, 255, 0.4);
      border-radius: 4px;
      text-align: center;
      line-height: 38px;
      font-size: 20px;
      color: #FFFFFF;
    }
    .login-msg-box{
      // position: absolute;
      // left: 50%;
      // top: 240px;
      // margin: 0 0 0 -488px;
      margin: 133px auto 0;
      width: 975px;
      .contract-us{
        margin-right: 122px;
        padding: 80px 0 0 0;
        width: 378px;
        display: inline-block;
        vertical-align: top;
        .title{
          // height: 84px;
          line-height: 72px;
          font-size: 72px;
          font-weight: normal;
         color: #F7FBFF;
         margin-bottom: 30px;
        }
        .contract-tip{
          font-size: 18px;
          color: #FCFEFF;
          line-height: 27px;
        }
        .arrow-right{
          margin-top: 14px;
          width: 19px;
          height: 16px;
          display: inline-block;
          background: url("./../../assets/img/login/right_arrow.svg") no-repeat;
          cursor: auto;
        }
      }
      &::after{
        content: "";
        display: block;
        clear: both;
      }
    }
  // }
}
@media screen and (min-width: 1920px){
    .login-box{
      background-size: 100% 100%;
    }
}
</style>