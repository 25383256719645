export default{
  data () {
    return {
      
    }
  },
  methods: {
    inputBlur(type){
      switch (type){
        case 'loginname':
          if(this.userName.trim() == ''){
            this.isUserTip = true;
            this.userTip = '请输入用户名、手机号或邮箱';
          }
          //  else if(!this.checkPhone(this.userName) && !this.checkEmail(this.userName)){
          //   this.isUserTip = true;
          //   this.userTip = '请输入正确的手机号或邮箱';
          // }
          else{
            this.isUserTip = false;
            this.userTip = '';
          }
          break;
        case 'username':
          if(this.userName.trim() == ''){
            this.isUserTip = true;
            this.userTip = '请输入手机号';
          }else if(!this.checkPhone(this.userName)){
            this.isUserTip = true;
            this.userTip = '请输入正确的手机号';
          }else{
            this.isUserTip = false;
            this.userTip = '';
          }
        break;
        case 'password':
          if(this.loginPwd.trim() == ''){
            this.isPsdTip = true;
            this.psdTip = '请输入密码';
          }else{
            this.isPsdTip = false;
            this.psdTip = '';
          }
        break;
        case 'verifyCode':
          if(this.verifyCode.trim() == ''){
            this.isVerifyCode = true;
            this.verifyCodeTip = '请输入图形验证码';
          }else{
            this.isVerifyCode = false;
            this.verifyCodeTip = '';
          }
        break;
        case 'codeName':
          if(this.codeName.trim() == ''){
            this.isUserTip = true;
            this.userTip = '请输入手机号';
          }else if(!this.checkPhone(this.codeName)){
            this.isUserTip = true;
            this.userTip = '请输入正确的手机号';
          }else {
            this.isUserTip = false;
            this.userTip = '';
          }
        break;
        case 'identifyCode':
          if(this.identifyCode.trim() == ''){
            this.isCodeTip = true;
            this.codeTip = '请输入验证码';
          }else{
            this.isCodeTip = false;
            this.codeTip = '';
          }
        break;
        case 'companyName':
          if(this.companyName.trim() == ''){
            this.isUserTip = true;
            this.userTip = '请输入账号';
          }else{
            this.isUserTip = false;
            this.userTip = '';
          }
        break;
        case 'companyPwd':
          if(this.companyPwd.trim() == ''){
            this.isPsdTip = true;
            this.psdTip = '请输入密码';
          }else{
            this.isPsdTip = false;
            this.psdTip = '';
          }
        break;
        case 'newPsd':
          if(this.newPsd.trim() == ''){
            this.isPsdTip = true;
            this.psdTip = '请输入密码';
          }else if(this.$store.state.noPsdRegexp.test(this.newPsd) || this.newPsd.length<8 || this.getLength(this.newPsd)<2){
            this.isPsdTip = true;
            this.psdTip = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)'; 
          }else if(this.confirmPsd.trim()!='' && this.newPsd != this.confirmPsd){
            this.isPsdTip = true;
            this.psdTip = '两次输入的密码不一致';
            this.isConfPsdTip = true;
            this.confPsdTip = '两次输入的密码不一致';
          }else{
            this.isPsdTip = false;
            this.psdTip = '';
            this.isConfPsdTip = false;
            this.confPsdTip = '';
          }
        break;
        case 'confirmPsd':
          if(this.confirmPsd.trim() == ''){
            this.isConfPsdTip = true;
            this.confPsdTip = '请输入密码';
          }else if(this.$store.state.noPsdRegexp.test(this.confirmPsd) || this.confirmPsd.length<8 || this.getLength(this.confirmPsd)<2){
            this.isConfPsdTip = true;
            this.confPsdTip = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)'; 
          }else if(this.newPsd.trim()!='' && this.newPsd != this.confirmPsd){
            this.isPsdTip = true;
            this.psdTip = '两次输入的密码不一致';
            this.isConfPsdTip = true;
            this.confPsdTip = '两次输入的密码不一致';
          }else{
            this.isPsdTip = false;
            this.psdTip = '';
            this.isConfPsdTip = false;
            this.confPsdTip = '';
          }
        break;
      }
    },
    getLength(val){
      var count = 0;
      for(var i in this.$store.state.psdRegexp){
        if(this.$store.state.psdRegexp[i].test(val)){
          count++;
        }
      }
      return count;
    }
  }
}